import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Image from 'next/image';

import { Text } from 'components/formatted-message';

const featureList = ['control', 'environment', 'content'];

export default function HomeTemplate() {
  return (
    <Box mx="auto" px={{ xxs: 0, sm: 6 }} width="100%">
      <Text align="center" id="title" variant="h1" />
      <Text align="center" id="description" maxWidth={641} mt={4} mx="auto" />
      <Stack
        direction={{ xxs: 'column', md: 'row' }}
        justifyContent={{ xxs: 'flex-start', lg: 'center' }}
        mt={8}
        spacing={10}>
        <List sx={{ maxWidth: 500 }}>
          {featureList.map(feature => (
            <ListItem key={feature} disableGutters>
              <ListItemText
                primary={<Text id={`features.${feature}.title`} variant="h3" gutterBottom />}
                secondary={<Text color="textSecondary" id={`features.${feature}.description`} />}
                disableTypography
              />
            </ListItem>
          ))}
        </List>
        <Hidden lgDown>
          <Box height={322} maxWidth={459} position="relative" width={{ xxs: '100%', sm: 459 }}>
            <Image alt="stats" sizes="100vw" src="/home-stats.svg" fill />
          </Box>
        </Hidden>
      </Stack>
    </Box>
  );
}
