import { getLayout } from 'layouts/base-layout';
import HomeTemplate from 'templates/home';

const HomePage = HomeTemplate;

HomePage.getLayout = getLayout;
HomePage.translations = ['base-layout', 'home'];

export default HomePage;

export function getServerSideProps() {
  return { props: {} };
}
